import React, { MouseEvent } from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../reducers';
import { Box, ButtonBase, Drawer, Paper, Typography } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import Text from '../common/Text';
import { SelectOption } from '../../common/types';
import { DueDiligenceChartDTO, DEFAULT_NEW_CHART } from '../../actions/types/dueDiligence';
import { createDueDiligenceChart, deleteDueDiligenceChart, getDueDiligenceCharts, selectDueDiligenceChart, processDueDiligenceChart, clearChartOptions, saveSelectedDueDiligenceChartUpdates } from '../../actions/dueDiligence';
import Button from '../common/Button';
import { Select } from '../common/styled/Select';
import SaveAsModal from '../CustomCMA/SaveAsModal';
import ConfirmDeleteSetModal from '../CustomCMA/ConfirmDeleteSetModal';
import Tabs from './Tabs';
import ChartSection from './ChartSection';
import ScreenerFilters from './Screener/ScreenerFilters';
import ScreenerResults from './Screener/ScreenerResults';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FONT_STYLES_BY_TYPE } from '../common/styled/Text';

const CREATE_NEW_CHART_OPTION: SelectOption<DueDiligenceChartDTO> = { value: 0, label: 'Create New Chart', item: {...DEFAULT_NEW_CHART} } 

interface ReduxStateProps {
    loading: boolean;
    processingChart: boolean;
    items: DueDiligenceChartDTO[]
    selectedChartItem: DueDiligenceChartDTO
}

interface ReduxActionProps {
    getDueDiligenceCharts: typeof getDueDiligenceCharts;
    deleteDueDiligenceChart: typeof deleteDueDiligenceChart;
    selectDueDiligenceChart: typeof selectDueDiligenceChart;
    processDueDiligenceChart: typeof processDueDiligenceChart;
    clearChartOptions: typeof clearChartOptions;
    saveSelectedDueDiligenceChartUpdates: typeof saveSelectedDueDiligenceChartUpdates;
    createDueDiligenceChart: (payload: Partial<DueDiligenceChartDTO>) => Promise<DueDiligenceChartDTO | null>;
}


interface ComponentState {
    items: SelectOption<DueDiligenceChartDTO>[]
    selectedChartItemOption: SelectOption<DueDiligenceChartDTO> | null
    showSaveAsModal: boolean
    showConfirmDeleteModal: boolean
    drawerWidth: number | string
}

type ComponentProps = ReduxActionProps & ReduxStateProps

class DueDiligenceCharts extends React.Component<ComponentProps, ComponentState> {
    state: ComponentState = {
        items: [CREATE_NEW_CHART_OPTION],
        showSaveAsModal: false,
        showConfirmDeleteModal: false,
        selectedChartItemOption: {...CREATE_NEW_CHART_OPTION},
        drawerWidth: 60,
    }


    componentDidMount(): void {
        this.props.getDueDiligenceCharts()
        
    }
    componentDidUpdate(prevProps: Readonly<ComponentProps>): void {
        if (prevProps.items !== this.props.items) {
            const items = this.props.items.map(item => ({
                value: item.id,
                label: item.name,
                item,
            })).sort((a, b) => a?.item?.name?.localeCompare(b?.item?.name))
            this.setState({ items: [CREATE_NEW_CHART_OPTION, ...items] })
        }

        if (prevProps.selectedChartItem !== this.props.selectedChartItem) {
            if (this.props.selectedChartItem.id) {
                this.setState({ selectedChartItemOption: { value: this.props.selectedChartItem.id, label: this.props.selectedChartItem.name, item: this.props.selectedChartItem } })
            } else {
                this.setState({ selectedChartItemOption: {...CREATE_NEW_CHART_OPTION} })
            }
        }
    }

    onChartSelected = async (selectedChartItem: SelectOption<DueDiligenceChartDTO> | null) => {
        if (selectedChartItem?.item) {
            await this.props.selectDueDiligenceChart(selectedChartItem?.item)
            if (selectedChartItem.item.id) {
                this.props.processDueDiligenceChart()
            } else {
                this.props.clearChartOptions()
            }
        }
    }

    onSaveAs = () => {
        this.setState({ showSaveAsModal: true })
    }

    onCloseModals = () => {
        this.setState({ showSaveAsModal: false, showConfirmDeleteModal: false })
    }

    onSaveAsConfirmed = async (name: string) => {
        this.setState({ showSaveAsModal: false })
        const { selectedChartItem } = this.props;
        this.props.createDueDiligenceChart({ ...selectedChartItem, name });
    }

    onSave = () => {
        this.props.saveSelectedDueDiligenceChartUpdates()
    }

    onDelete = () => {
        this.setState({ showConfirmDeleteModal: true })
    }

    onConfirmDelete = () => {
        const {selectedChartItem} = this.props;
        if (!selectedChartItem.id) {
            return;
        }

        this.props.deleteDueDiligenceChart(selectedChartItem.id)
        this.setState({ showConfirmDeleteModal: false })
    }

    onExpandClicked = () => {
        const { drawerWidth } = this.state;
        this.setState({ drawerWidth: drawerWidth === 60 ? '100%' : 60 })
    }
    
    render() {
        const {loading, processingChart, selectedChartItem} = this.props;
        const {items, showSaveAsModal, showConfirmDeleteModal, selectedChartItemOption, drawerWidth} = this.state;
        const canModify = selectedChartItem.id > 0 && !selectedChartItem.is_system

        return (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ display: 'flex' }}>
                    <Drawer
                        variant="permanent"
                        sx={{
                            width: drawerWidth, 
                            position: 'relative',
                            mr: '2.5rem',
                            '& .MuiDrawer-paper': {
                                overflow: 'hidden',
                                position: 'relative',
                                width: drawerWidth,
                            },
                        }}
                        >
                        <Box sx={{ display: 'flex', flexDirection: 'row', position: 'relative', height: '100%' }}>
                            {drawerWidth === 60 && (
                                <ButtonBase sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '5rem' }} onClick={this.onExpandClicked}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10rem' }}>
                                        <Box sx={{ width: '2rem', height: '6rem', transform: `rotate(180deg)`  }}>
                                            <Typography sx={{ transform: `rotate(90deg)`, font: FONT_STYLES_BY_TYPE.text}}>Screen</Typography>
                                        </Box>
                                        <Box>
                                            <FontAwesomeIcon icon={faChevronRight} />
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </Box>
                                    </Box>
                                </ButtonBase>
                            )}
                            {drawerWidth !== 60 && (
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <ScreenerFilters />
                                    <ScreenerResults />
                                </Box>
                            )}
                            {drawerWidth !== 60 && (
                                <ButtonBase sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '5rem' }} onClick={this.onExpandClicked}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10rem' }}>
                                        <Box>
                                            <FontAwesomeIcon icon={faChevronLeft} />
                                            <FontAwesomeIcon icon={faChevronLeft} />
                                        </Box>
                                        <Box sx={{ width: '2rem', height: '5rem' }}>
                                            <Typography sx={{ transform: `rotate(90deg)`, font: FONT_STYLES_BY_TYPE.text}}>Chart</Typography>
                                        </Box>
                                    </Box>
                                </ButtonBase>
                            )}
                        </Box>
                    </Drawer>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '50rem', ml: '2.5rem', position: 'relative' }}>
                    {loading || processingChart && 
                        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', zIndex: 100, top: -10, left: -10, right: -10, bottom: -10, alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFF', opacity: 0.4, padding: '5rem' }}>
                            {!processingChart && <ClipLoader loading={true} size={24} />}
                        </Box>
                    }
                    <SaveAsModal
                        visible={showSaveAsModal}
                        name={selectedChartItem.name ?? ''}
                        onSave={this.onSaveAsConfirmed}
                        onCancel={this.onCloseModals}
                    />
                    <ConfirmDeleteSetModal
                        visible={showConfirmDeleteModal}
                        item={selectedChartItem}
                        onConfirm={this.onConfirmDelete}
                        onCancel={this.onCloseModals}
                    />
                    <Box sx={{ display: 'flex', marginBottom: '1.5rem', justifyContent: { xs: 'unset', xl: 'center' }, flexDirection: { xs: 'column', xl: 'column' } }}>
                        <Select sx={{ width: '35rem', marginRight: '1rem' }} options={items} value={selectedChartItemOption} onChange={this.onChartSelected} />

                        <Box sx={{ marginTop: '1rem', display: 'flex', alignItems: 'center' }}>
                            {canModify && <Button autoFit sx={{ height: '4.5rem' }} title={'Save'} onClick={this.onSave} />}
                            <Button autoFit sx={{ height: '4.5rem' }} title={'Save As'} onClick={this.onSaveAs} />
                            {canModify && <Button autoFit sx={{ height: '4.5rem' }} title={'Delete'} onClick={this.onDelete} />}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, height: '100%' }}>
                        <Tabs />
                    </Box>
                </Box>
                <ChartSection />
            </Box>
        )
    }
}


const mapStateToProps = ({ dueDiligence }: ReduxState): ReduxStateProps => {
    const { loadingCharts: loading, processingChart, charts: items, selectedChartItem } = dueDiligence;
    return {
        items,
        loading,
        selectedChartItem,
        processingChart,
    }
}

export default connect(mapStateToProps, { getDueDiligenceCharts, deleteDueDiligenceChart, createDueDiligenceChart, selectDueDiligenceChart, processDueDiligenceChart, clearChartOptions, saveSelectedDueDiligenceChartUpdates })(DueDiligenceCharts as any)