import React from 'react';
import Box from '@mui/material/Box';
import { ReportResourceItem } from '../../../actions/types/portfolioDesigner';
import Text from '../../../components/common/Text';
import { ResultTable } from '../ResultTable';
import ChartTable from '../ChartTable';
import HistoricalScenarioAnalysis from '../HistoricalScenarioAnalysis';
import Chart from '../../../components/common/Chart';

export interface RowProps {
    items: ReportResourceItem[]
}

export const Row = ({items}: RowProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
        {items.map((item) => {
            if(item.type === 'table' && item.name === 'Historical Scenario Analysis') {
                return (<HistoricalScenarioAnalysis item={item} />)
            }
            if(item.type === 'highchart' && item.name === 'Correlation Matrix') {
                const categories = item.options?.xAxis?.categories ?? [];
                const categoryCount = categories.length;
                const width = Math.min(Math.max(categoryCount * 2.5, 75), 140);
                const height = Math.min(Math.max(categoryCount * 1.5, 50), 115);

                return (<Chart sx={{ minWidth: '75rem', minHeight: '50rem', maxWidth: '140rem', maxHeight: '115rem', width: `${width}rem`, height: `${height}rem` }} id={item.name} title={item.name} chartOptions={item.options} />)
            }
            if (item.type === 'table') {
                return (
                    <ResultTable 
                        key={item.name}
                        tableSx={{
                            height: '31.75rem',
                            width: '55rem',
                        }}
                        containerSx={{
                            marginTop: {
                                xs: '1rem',
                                md: undefined, 
                            },
                            marginRight: {
                                xs: undefined,
                                md: '1rem',
                            }
                        }}
                        results={item}
                        excludeKeys={['']}
                    />
                )
            }
            if (item.type === 'chart') {
                return (
                    <ChartTable 
                        chart={item} 
                        tableSx={{ height: '30rem' }}
                        sx={{
                            marginTop: {
                                xs: '1rem',
                                md: undefined, 
                            },
                            marginRight: {
                                xs: undefined,
                                md: '1rem',
                            }
                        }}
                    />
                )
            }
            
            return null;
        })}
    </Box>
  );
}