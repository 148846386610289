import { Component } from "react"
import { connect } from 'react-redux'
import { ChartOptions } from "../../../OptimizedPortfolio/chartOptions"
import { ReduxState } from "../../../../reducers"
import Chart from "../../../common/Chart"

export interface CorrelationMatrixProps {
    snapshotCorrelationMatrixChart: ChartOptions
}

export interface CorrelationMatrixState { }

class CorrelationMatrix extends Component<CorrelationMatrixProps, CorrelationMatrixState> {

    render() {
        const { snapshotCorrelationMatrixChart } = this.props
        if (!snapshotCorrelationMatrixChart) {
            return null;
        }
        const categories = snapshotCorrelationMatrixChart?.xAxis?.categories ?? [];
        const categoryCount = categories.length;
        const width = Math.min(Math.max(categoryCount * 3, 75), 125);
        const height = Math.min(Math.max(categoryCount * 2, 50), 100);
        return <Chart sx={{ minWidth: '50rem', minHeight: '50rem', maxWidth: '125rem', maxHeight: '100rem', width: `${width}rem`, height: `${height}rem` }} id='snapshotCorrelationMatrixChart' chartOptions={snapshotCorrelationMatrixChart} />;
    }
}

const mapStateToProps = ({ portfolioDesigner }: ReduxState ) => {
    const { snapshotCorrelationMatrixChart } = portfolioDesigner

    return { snapshotCorrelationMatrixChart }
}

export default connect(mapStateToProps, {  })(CorrelationMatrix as  any)
