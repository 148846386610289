import { Box, SxProps } from '@mui/material'
import React, { Component, FunctionComponent } from 'react'
import { FONT_STYLES_BY_TYPE } from './Text'
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseProps } from '../../types';
import { Theme } from '@emotion/react';

interface DatePickerProps extends BaseProps {
    date: string | Date
    format?: string
    showMonthYearPicker?: boolean
    onDateChanged: (date: any) => void
    minDate?: Date
    maxDate?: Date
    inputSx?: SxProps<Theme>;
    datePickerSx?: SxProps<Theme>;
    calendarPopupSx?: SxProps<Theme>;
    bottomPlacementSx?: SxProps<Theme>;
    plain?: boolean
    placeholderText?: string | Date | undefined,
    disabled?: boolean
}

export class DatePicker extends Component<DatePickerProps> {
  state = {
    selectedDate: this.props?.date
  }

  componentDidUpdate(prevProps: Readonly<DatePickerProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const { date } = this.props
    if (prevProps.date !== date) {
      this.setState({ selectedDate: date })
    }
  }

  onDateChangedInternalHandle = (value: Date) => {
    const { maxDate, minDate, onDateChanged } = this.props
    if (maxDate && value > maxDate) {
      this.setState({ selectedDate: maxDate })
    } else if(minDate && value < minDate) {
      this.setState({ selectedDate: minDate })
    } else {
      this.setState({ selectedDate: value })
    }
  }

  onDateChanged = () => {
    const { onDateChanged } = this.props
    if (this.props.date === this.state.selectedDate) return;
    
    onDateChanged(this.state.selectedDate)
  }

  render() {
    const {
        sx = { },
        inputSx = { },
        datePickerSx = { },
        calendarPopupSx = { },
        bottomPlacementSx = { },
        showMonthYearPicker,
        format,
        minDate,
        maxDate,
        plain,
        placeholderText,
      } = this.props
    return (
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          zIndex: 100,
          "& input": {
            height: '5rem',
            border: plain ? 'unset' :`1px solid #ccc` ,
            borderRadius: 0,
            padding: '1rem',
            width: '16rem',
            font: FONT_STYLES_BY_TYPE.text_small,
            ...(inputSx as any)
          },
          "& .react-datepicker": {
            font: FONT_STYLES_BY_TYPE.text_tiny,
            ...(datePickerSx as any),
          },
          "& .react-datepicker-year-header": {
            font: FONT_STYLES_BY_TYPE.text_tiny,
          },
          '& .react-datepicker-popper[data-placement^="bottom"]': {
                marginTop: "-10px",
              ...(bottomPlacementSx as any),
          },
          '& .react-datepicker-popper': {
            ...(calendarPopupSx as any),
          },
          ...sx
        }}>
            <ReactDatePicker
                dateFormat={format}
                showMonthYearPicker={showMonthYearPicker}
                selected={this.state.selectedDate}
                minDate={minDate}
                maxDate={maxDate}
                placeholderText={this.state.selectedDate || placeholderText}
                onChange={this.onDateChangedInternalHandle}
                onCalendarClose={this.onDateChanged}
                onBlur={this.onDateChanged}
                disabled={this.props.disabled}
              />
              {!plain &&
                <Box sx={{
                  height: '3.5rem',
                  width: '3.5rem',
                  color: (theme) => theme.palette.text.level7,
                  position: 'absolute',
                  top: '1.3rem',
                  right: '-5px',
                }}> 
                  <FontAwesomeIcon size='2x' icon={faCalendar} />
                </Box>
              }
        </Box>
    )
  }
}