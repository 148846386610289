import html2canvas from 'html2canvas';
import { Box, SxProps } from '@mui/material'
import React, { Component } from 'react'
import { LooseObject } from '../../common/types'
import { ChartOptions } from '../OptimizedPortfolio/chartOptions'
import { BaseProps } from '../types'
import { ClipLoader } from 'react-spinners';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import HighchartsExporting from 'highcharts/modules/exporting';
import { v4 } from 'uuid'
import Text from './Text'
import ExportMenu from './ChartExport';
import PdComponent from './PdComponent';
import moment from 'moment';
import { Theme } from '../../theme/theme';

HighchartsHeatmap(Highcharts);  // Initialize the heatmap module

export interface ChartMouseEvent {
    category: number;
    clientX: number;
    color: string;
    colorIndex: number;
    destroyed: boolean;
    dist: number;
    distX: number;
    events: { [key: string]: any };
    formatPrefix: string;
    graphic: { [key: string]: any };
    hasImage: undefined | boolean;
    hasImportedEvents: boolean;
    hcEvents: { [key: string]: any };
    id: string;
    index: number;
    isInside: boolean;
    isNull: boolean;
    name: undefined | string;
    negative: boolean;
    options: { [key: string]: any };
    percentage: undefined | number;
    plotX: number;
    plotY: number;
    selected: boolean;
    series: { [key: string]: any };
    shapeArgs: undefined | { [key: string]: any };
    state: string;
    total: undefined | number;
    visible: boolean;
    x: number;
    y: number;
    yBottom: undefined | number;
    zone: undefined | { [key: string]: any };
}
  
export interface ZoomOptions {
    min?: number
    max?: number
    enabled: boolean
}

export interface ChartProps extends BaseProps {
    id: string
    title?: string | React.ReactNode
    chartOptions: LooseObject
    zoomOptions?: ZoomOptions
    loading?: boolean
    footer?: React.ReactNode
    attribution?: React.ReactNode
    showExport?: boolean
    footerAttributionSx?: SxProps<Theme>
    footerOnlyInExport?: boolean
    headerContainerProps?: SxProps<Theme>
    exportTitle?: string
    containerSx?: SxProps<Theme>
    onZoom?: (id: string, chart: any, zoomOptions: ZoomOptions) => void
    onChartMouseOut?: (id: string, event: ChartMouseEvent) => any
    onChartMouseOver?: (id: string, event: ChartMouseEvent) => any
}

export interface ChartState {
    chartOptions: LooseObject
    chartKey: string
    exporting: boolean
}

export default class Chart extends PdComponent<ChartProps, ChartState>{
    containerRef: any
    private chartRef = React.createRef<any>();
    private chartContainerRef = React.createRef<any>();


    constructor(props: ChartProps) {
        super(props)

        this.containerRef = undefined
        this.state = {
            chartOptions: props.chartOptions,
            chartKey: v4(),
            exporting: false,
        }
    }

    componentDidMount(): void {
        this.onUpdateHighchart(this.props.chartOptions)
    }

    componentDidUpdate(prevProps: ChartProps) {
        const { chartOptions: prevChartOptions, zoomOptions: prevZoomOptions } = prevProps
        const { chartOptions, zoomOptions} = this.props

        if(chartOptions && prevChartOptions !== chartOptions) {
            this.onUpdateHighchart(chartOptions)
        }
        if(prevZoomOptions?.enabled !== zoomOptions?.enabled ||
            prevZoomOptions?.min !== zoomOptions?.min ||
            prevZoomOptions?.max !== zoomOptions?.max
          ) {
            this.zoom()
        }
    }

    zoom = () => {
        const { zoomOptions } = this.props
        if(zoomOptions?.enabled) {
            this.chartRef.current.chart.xAxis[0].setExtremes(zoomOptions.min, zoomOptions.max)
        }
        if(!zoomOptions?.enabled) {
            this.chartRef.current.chart.zoomOut();
        }
    }
    

    onSelection = (event: any) => {
        const { resetSelection, xAxis } = event
        const  { [0]: { min = 0, max = 0 } } = xAxis || [{}]
        const zoomOptions = {
            min,
            max,
            enabled: !resetSelection
        }
        if(this.props.onZoom) {
            this.props.onZoom(this.props.id, this.chartRef, zoomOptions)
        }
    }

    onMouseOver = ({ target }: any) => {
        if (this.props.onChartMouseOver) {
            const { id } = this.props;
            this.props.onChartMouseOver(id, target as ChartMouseEvent);
        }
    }

    onMouseOut = ({ target }: any) => {
        if (this.props.onChartMouseOut) {
            const { id } = this.props;
            this.props.onChartMouseOut(id, target as ChartMouseEvent);
        }
    }

    prepPointEvents = (options: ChartOptions): ChartOptions => {
        return {
            ...options,
            plotOptions: {
                ...(options?.plotOptions || { }),
                series: {
                    ...(options?.plotOptions?.series || { }),
                    point: {
                        ...(options?.plotOptions?.series?.point || { }),
                        events: {
                            ...(options?.plotOptions?.series?.point?.events || { }),
                        }
                    }

                }
            }
        }
    }

    onUpdateHighchart = (options: ChartOptions) => {
        let chartOptions = this.prepPointEvents(options)
        let optionsChart = chartOptions?.chart || { }
        const chartOptionsChartEvents = optionsChart?.events || { }
        const chartOptionsChartEventRender = chartOptionsChartEvents?.render

        chartOptionsChartEvents.selection = this.onSelection
        
        if (this.props.onChartMouseOver) {
            chartOptions.plotOptions.series.point.events = {
                ...chartOptions.plotOptions.series.point.events,
                mouseOver: this.onMouseOver
            }
        }
        if (this.props.onChartMouseOut) {
            chartOptions.plotOptions.series.point.events = {
                ...chartOptions.plotOptions.series.point.events,
                mouseOut: this.onMouseOut
            }
        }
    
    
        if(chartOptionsChartEventRender) {
            optionsChart = { ...chartOptions, chart: optionsChart ? { ...optionsChart, events: { ...chartOptionsChartEvents, render: chartOptionsChartEventRender ? (chart: any) => chartOptionsChartEventRender(chart) : undefined  }} : undefined }
        } else {
            optionsChart = { ...chartOptions, chart: { ...optionsChart, events: { ...chartOptionsChartEvents }}}
        }
       this.setState({ chartOptions: optionsChart, chartKey: v4() })
    }
    
    downloadImage = (dataUrl: string, filename: string) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    exportChart = async () => {
        if (!this.chartContainerRef.current) {
            return;
        }
        
        try {
            const { exportTitle, title } = this.props;
            const titleText = exportTitle ? exportTitle : typeof title === 'string' ? title : '';
            const time = moment().tz('America/New_York').format('YYYY-MM-DD.hh.mm.a');
            const name = `${titleText || 'exported-chart'}-${time}.png`.replace(/ /g, '-').toLowerCase();

            await this.setStateAsync({ exporting: true });
            const canvas = await html2canvas(this.chartContainerRef.current, { useCORS : true, imageTimeout: 1000, allowTaint: true, logging: true });
            this.setState({ exporting: false });
            const image = canvas.toDataURL("image/png");
            this.downloadImage(image, name);
        } catch (error) {
            console.error('Error capturing div:', error);
        }
    }

    render() {
        const { sx = {}, containerSx = {}, onChartMouseOver: _onChartMouseOver, onZoom: _onZoom, zoomOptions: _zoomOptions, title, footerAttributionSx = {}, footerOnlyInExport, headerContainerProps = {}, ...restProps } = this.props
        const { chartKey, chartOptions = { title: { text: '' } }, exporting } = this.state
        const shouldShowFooter = this.props.footer !== undefined && footerOnlyInExport ? exporting : true
        return (
            <Box sx={{ display: 'flex', marginTop: '1rem', ...containerSx }}>
                <Box ref={this.chartContainerRef} sx={{ display: 'flex', flexDirection: 'column', padding: exporting ? '1rem' : undefined, zIndex: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1rem', ...headerContainerProps }}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            {title && typeof title === 'string' && <Text type={'table_header'}>{this.props.title}</Text>}
                            {title && typeof title !== 'string' && this.props.title}
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            {!exporting && this.props.showExport && <ExportMenu onDownloadImage={this.exportChart} />}
                        </Box>
                    </Box>
                    <Box sx={{ marginTop: chartOptions?.legend?.enabled ? undefined : '2rem', overflow: 'hidden', zIndex: 1, ...sx}} {...restProps}>
                        {this.props.chartOptions && <HighchartsReact ref={this.chartRef} key={chartKey} containerProps={{ style: { width: (sx as any)?.width, height: (sx as any)?.height, padding: '1rem' }}} highcharts={Highcharts} updateArgs={[chartOptions]} options={chartOptions} /> }
                        {this.props.loading && 
                            <Box 
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: '#0005'
                                }}>
                                    <ClipLoader />
                                </Box>
                        }
                    </Box>
                    <Box sx={{ display: 'flex', zIndex: 5, ...footerAttributionSx }}>
                        {shouldShowFooter && 
                            <Box sx={{ display: 'flex', flex: 1}}>
                                {this.props.footer}
                            </Box>
                        }
                        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                            {this.props.attribution}
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

}