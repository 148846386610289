import React from 'react';
import { connect } from 'react-redux';
import { Box, List } from '@mui/material';
import moment from 'moment';

import { ReduxState } from '../../reducers';
import { DueDiligenceChartDTO, Metric } from '../../actions/types/dueDiligence';
import { updateDueDiligenceChart, processDueDiligenceChart } from '../../actions/dueDiligence';
import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE, DateLogs } from '../../actions/types/portfolioDesigner';
import { DatePicker } from '../../components/common/styled/DatePicker';
import { LooseObject, TableRowData } from '../../common/types';
import { TimeRangePicker } from '../common/TimeRangePicker';
import { TimeRange, TimeRangeDates } from '../common/ranges';
import { WithWindowDimensionsProps, withWindowDimensions } from '../common/withWindowDimensions';
import Chart from '../../components/common/Chart';
import { ResultDateLogs } from '../PortfolioDesigner/ResultDateLogs';
import { AccountLogo } from '../common/styled/AccountLogo';
import { LogoHorizontalImage } from '../../assets';
import Text from '../common/Text';
import ListRow, { RowType } from './Tabs/ListRow';
import { addError } from '../../actions/notifications';
import RollingCorrelationInputsModal from './Tabs/RollingCorrelationInputsModal';
import ExtendedCommonTable from '../common/ExtendedCommonTable';

const METRIC_ITEMS: Metric[] = [Metric.TOTAL_RETURN, Metric.PRICE, Metric.PRICE_RETURNS, Metric.DRAWDOWN, Metric.ROLLING_CORRELATION, Metric.ROLLING_RETURNS]
const METRIC_ITEM_LABELS: { [key: string]: string } = {
    [Metric.TOTAL_RETURN]: 'Total Return',
    [Metric.PRICE]: 'Price',
    [Metric.PRICE_RETURNS]: 'Price (%)',
    [Metric.DRAWDOWN]: 'Drawdown',
    [Metric.ROLLING_CORRELATION]: 'Rolling Correlation',
    [Metric.ROLLING_RETURNS]: 'Rolling Returns (Ann. Monthly)',
}

interface ReduxStateProps extends WithWindowDimensionsProps{
    item?: DueDiligenceChartDTO
    dueDiligenceChartOptions: LooseObject
    processingChart: boolean
    date_logs?: DateLogs
    hasLogo: boolean
    hypotheticalDataUsed?: boolean
    correlationMatrixChart: LooseObject
    statisticsTable: TableRowData[]
    statisticsColumns: LooseObject[]
    result?: any
}

interface ReduxActionProps {
    updateDueDiligenceChart: typeof updateDueDiligenceChart;
    processDueDiligenceChart: typeof processDueDiligenceChart;
    addError: typeof addError;
}

type ComponentProps = ReduxActionProps & ReduxStateProps

interface ComponentState {
    start_date?: Date;
    end_date?: Date;
    rollingCorrelationModalVisible: boolean;
}

class InputTab extends React.Component<ComponentProps, ComponentState> {
    state: ComponentState = {
        start_date: DEFAULT_MIN_DATE,
        end_date: DEFAULT_MAX_DATE,
        rollingCorrelationModalVisible: false,
    }

    componentDidMount(): void {
        this.updateDateRangeFromItem(this.props.item);
    }

    updateDateRangeFromItem = (item?: DueDiligenceChartDTO) => {
        if (!item) {
            this.setState({ start_date: DEFAULT_MIN_DATE, end_date: DEFAULT_MAX_DATE })

            return;
        }

        const {start_date, end_date} = item;

        this.setState({ start_date: moment(start_date ?? DEFAULT_MIN_DATE).toDate(), end_date: moment(end_date ?? DEFAULT_MAX_DATE).toDate()})
    }

    componentDidUpdate(prevProps: Readonly<ComponentProps>): void {
        if (prevProps.item?.start_date !== this.props.item?.start_date || prevProps.item?.end_date !== this.props.item?.end_date || prevProps.item?.date_range !== this.props.item?.date_range) {
            this.updateDateRangeFromItem(this.props.item);
        }
    }

    onStartDateUpdated = (date: Date) => {
        const {item} = this.props;
        this.props.updateDueDiligenceChart(item?.id ?? 0, { start_date: moment(date).format('YYYY-MM-DD'), date_range: null })
    }

    onEndDateUpdated = (date: Date) => {
        const {item} = this.props;
        this.props.updateDueDiligenceChart(item?.id ?? 0, { end_date: moment(date).format('YYYY-MM-DD'), date_range: null })
    }

    onRangePicker = (range: TimeRange, { start_date, end_date }: TimeRangeDates) => {
        const {item} = this.props
        const {date_range} = item ?? {};

        if (range.unit === date_range?.unit && range.duration === date_range?.duration) {
            this.props.updateDueDiligenceChart(item?.id ?? 0, { date_range: null })

            return;
        }
        
        this.props.updateDueDiligenceChart(item?.id ?? 0, { start_date: moment(start_date).format('YYYY-MM-DD'), end_date: moment(end_date).format('YYYY-MM-DD'), date_range: range })
    }

    onMetricCheckChanged = (metric: string, checked: boolean) => {
        const {item: chart, result} = this.props;
        if(!chart) {
            return;
        }

        const {id, metrics} = chart;
        let metricsUpdated = metrics.filter(item => item !== metric);
        if (checked) {
            metricsUpdated = [...metricsUpdated, metric as Metric]
        }
        if (metricsUpdated.length === 0) {
            this.props.addError('Display Error', 'At least one metric must be selected')
            return;
        }
        
        if (metricsUpdated.includes(Metric.ROLLING_CORRELATION)) {
            if (chart.tickers.length < 2) {
                this.props.addError('Display Error', 'Rolling Correlation requires at least 2 tickers')
                return;
            }
            
            if ((chart.rc_tickers.length !== 2 || !chart.rc_lag)) {
                this.setState({ rollingCorrelationModalVisible: true })
                return;
            }
            const {rolling_correlation} = result || {};
            const rcValidValues = Object.keys(rolling_correlation || {}).filter((key) => rolling_correlation[key] !== null);
            if (rolling_correlation && rcValidValues.length === 0) {
                this.props.addError('Rolling Correlation', 'No data available for the selected rolling correlation')
                metricsUpdated = metricsUpdated.filter(item => item !== Metric.ROLLING_CORRELATION)
            }
        }

        if (metricsUpdated.includes(Metric.ROLLING_RETURNS)) {
            const {rolling_returns} = result || {};
            const validValues = Object.keys(rolling_returns || {}).filter((key) => {
                if (rolling_returns[key] === null) {
                    return false
                }
                const innerKeys = Object.keys(rolling_returns[key])

                return innerKeys.filter((innerKey) => rolling_returns[key][innerKey] !== null).length > 0
            });
            if (rolling_returns && validValues.length === 0) {
                this.props.addError('Rolling Returns', 'No data available for rolling returns')
                metricsUpdated = metricsUpdated.filter(item => item !== Metric.ROLLING_RETURNS)
            }
        }

        metricsUpdated = metricsUpdated.slice(-2)

        this.props.updateDueDiligenceChart(id, { metrics: metricsUpdated })
    }

    onEdit = (metric: string) => {
        const {item: chart} = this.props;
        if(!chart) {
            return;
        }

        const {tickers} = chart;
        if (metric === Metric.ROLLING_CORRELATION  && tickers.length < 2) {
            this.props.addError('Display Error', 'Rolling Correlation requires at least 2 tickers')
        } else if (metric === Metric.ROLLING_CORRELATION) {
            this.setState({ rollingCorrelationModalVisible: true })
        }
    }

    onRenderMetricRow = (metric: Metric) => {
        const hasCustomAssets = this.props.item?.tickers?.find((ticker) => ticker.toUpperCase().endsWith('-CUSTOM')) !== undefined
        const hasIndexAssets = this.props.item?.tickers?.find((ticker) => ticker.toUpperCase().endsWith('-INDEX')) !== undefined
        const disabled = (hasCustomAssets || this.props.hypotheticalDataUsed || hasIndexAssets) && (metric === Metric.PRICE || metric === Metric.PRICE_RETURNS)
        const disabledReason = hasIndexAssets ? 'index assets' : hasCustomAssets ? 'custom assets' : 'backfill'
        const subtitle = disabled ? `(Disabled due to ${disabledReason})` : undefined
        const canEdit = [Metric.ROLLING_CORRELATION].includes(metric)

        return <ListRow key={metric} subtitle={subtitle} type={RowType.CHECKBOX} label={METRIC_ITEM_LABELS[metric]} item={metric} checked={this.props.item?.metrics?.includes(metric) ?? false} onCheckChanged={this.onMetricCheckChanged} onEdit={this.onEdit} showEdit={canEdit} showChecked disabled={disabled} />
    }

    onCloseRollingCorrelationModal = () => {
        this.setState({ rollingCorrelationModalVisible: false })
    }

    onConfirmRollingCorrelation = (chart: DueDiligenceChartDTO) => {
        const {item} = this.props;
        if (!item) {
            return;
        }

        const metricsCleaned = item.metrics.filter(metric => metric !== Metric.ROLLING_CORRELATION)
        const metrics = [...metricsCleaned, Metric.ROLLING_CORRELATION].slice(-2)

        this.props.updateDueDiligenceChart(item.id, { ...chart, metrics })
        this.setState({ rollingCorrelationModalVisible: false })
    }

    render() {
        const {dueDiligenceChartOptions, item, hasLogo, correlationMatrixChart} = this.props;
        const {start_date, end_date, rollingCorrelationModalVisible} = this.state;

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', }}>
                <RollingCorrelationInputsModal 
                    visible={rollingCorrelationModalVisible} 
                    onConfirm={this.onConfirmRollingCorrelation} 
                    onCancel={this.onCloseRollingCorrelationModal}
                />
                <List
                    sx={{
                        marginTop: '-1rem',
                        padding: 0,
                        paddingBottom: '2.5rem',
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        {METRIC_ITEMS.map((item) => {
                            return this.onRenderMetricRow(item as Metric)
                        })}
                </List>
                <Box sx={{ display: 'flex', marginBottom: '1rem', flexDirection: { xs: 'column', lg: 'row' }, height: '100%'  }}>
                    <TimeRangePicker onRangePicker={this.onRangePicker} max={DEFAULT_MAX_DATE} value={item?.date_range ?? undefined} />
                    <Box sx={{ 
                        display: 'flex',
                        flexDirection: 'row',
                        zIndex: 50,
                        marginTop: { xs: '1rem', lg: 'unset' },
                        marginLeft: { xs: 'unset', lg: '2.5rem' },
                    }}>
                        <DatePicker
                            sx={{ marginRight: '1rem'}}
                            date={start_date ?? DEFAULT_MIN_DATE}
                            format={'MM/dd/yyyy'}
                            minDate={DEFAULT_MIN_DATE}
                            maxDate={DEFAULT_MAX_DATE}
                            onDateChanged={this.onStartDateUpdated} 
                        />
                        <DatePicker
                            date={end_date ?? DEFAULT_MAX_DATE}
                            format={'MM/dd/yyyy'}
                            minDate={DEFAULT_MIN_DATE}
                            maxDate={DEFAULT_MAX_DATE}
                            onDateChanged={this.onEndDateUpdated} 
                        />
                    </Box>
                </Box>
                <Box sx={{ height: '100%' }}>
                    <Box sx={{ height: '100%' }}>

                        <Box>
                            <Chart 
                                id="dueDiligenceCharts" 
                                title={item?.name ?? ''}
                                sx={{ 
                                    width: { xs: this.props.dimensions.width - 100, lg: this.props.dimensions.width - 625} ,
                                    height: '75rem',
                                    backgroundColor: '#0000',
                                    zIndex: 0,
                                    position: 'relative',
                                    border: (theme) => `.10rem ${theme.palette.primary.main} solid`,
                                }}
                                loading={this.props.processingChart}
                                chartOptions={dueDiligenceChartOptions}
                                showExport={true}
                                footerAttributionSx={{  width: { xs: this.props.dimensions.width - 100, lg: this.props.dimensions.width - 625} }}
                                footer={
                                    this.props.date_logs ? 
                                    <ResultDateLogs
                                        logs={this.props.date_logs}
                                    />
                                    : undefined
                                }
                                attribution={
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Box>
                                            {hasLogo && <AccountLogo sx={{ maxHeight: '4rem', marginTop: '.25rem'}} />} 
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Text type='text_small' sx={{ marginRight: '1rem' }}>{moment().tz('America/New_York').format('MM-DD-YYYY hh:mm:ss a')} ET</Text>
                                            <Text type='text_small' sx={{ marginRight: '1rem' }}>Powerd By: </Text>
                                            <LogoHorizontalImage sx={{ height: '3rem', maxWidth: '40rem'}} />
                                        </Box>
                                    </Box>
                                }
                            />
                        </Box>
                        <Box sx={{ 
                            display: 'flex',
                            flexDirection: 'column',
                            mt: '5rem'
                        }}>
                            <Box sx={{ mt: '3rem' }}>
                                <ExtendedCommonTable
                                    title={this.props.date_logs ? <Text type={'h5'} sx={{ mb: '2rem' }}>{`Fund Statistics (${moment(start_date).format('MM/DD/YYYY')} - ${moment(end_date).format('MM/DD/YYYY')})`}</Text> : undefined}
                                    exportTitle={`Fund Statistics (${moment(start_date).format('MM/DD/YYYY')} - ${moment(end_date).format('MM/DD/YYYY')})`}
                                    tableSx={{
                                        ml: '7.5rem',
                                        maxWidth: '89rem',
                                        width: '100%', 
                                        '& th': {
                                            textAlign: 'center',
                                        },
                                        '& td': {
                                            textAlign: 'center',
                                        }
                                    }}
                                    headerColumns={this.props.statisticsColumns}
                                    rows={this.props.statisticsTable}
                                    showExport={!!this.props.date_logs}
                                    sx={{ 
                                        '& th': {
                                            textAlign: 'center',
                                        },
                                        '& td': {
                                            textAlign: 'center',
                                        },
                                    }}
                                    footerAttributionSx={{ maxWidth: '100rem' }}
                                    footerOnlyInExport={true}
                                    footer={
                                        this.props.date_logs ? 
                                        <ResultDateLogs
                                            logs={this.props.date_logs}
                                        />
                                        : undefined
                                    }
                                />
                            </Box>
                            <Chart
                                id='ddCorrelationMatrixChart'
                                containerSx={{ mt: '5rem', zIndex: 1 }}
                                headerContainerProps={{ mb: '-2rem', maxWidth: '150rem', minWidth: '98rem' }}
                                title={this.props.date_logs ? <Text type={'h5'}>{`Correlation Matrix (${moment(start_date).format('MM/DD/YYYY')} - ${moment(end_date).format('MM/DD/YYYY')})`}</Text> : undefined}
                                exportTitle={`Correlation Matrix (${moment(start_date).format('MM/DD/YYYY')} - ${moment(end_date).format('MM/DD/YYYY')})`}
                                sx={{ minWidth: '50rem', maxHeight: '50rem', maxWidth: '150rem', zIndex: 5 }}
                                chartOptions={correlationMatrixChart}
                                showExport={!!this.props.date_logs}
                                footerAttributionSx={{ maxWidth: '100rem', marginTop: '-3.5rem', zIndex: 10 }}
                                footerOnlyInExport={true}
                                footer={
                                    this.props.date_logs ? 
                                    <ResultDateLogs
                                        logs={this.props.date_logs}
                                    />
                                    : undefined
                                }
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }
}


const mapStateToProps = ({ dueDiligence, clientManagement }: ReduxState) => {
    const { logoAvailable: hasLogo } = clientManagement
    const {selectedChartItem, dueDiligenceChartOptions, processingChart, correlationMatrixChart, result, statisticsTable, statisticsColumns } = dueDiligence;


    return { item: selectedChartItem, dueDiligenceChartOptions, processingChart, date_logs: result?.date_logs, hasLogo, result, hypotheticalDataUsed: result?.hypotheticalDataUsed, correlationMatrixChart, statisticsTable, statisticsColumns }
}

export default connect(mapStateToProps, { updateDueDiligenceChart, processDueDiligenceChart, addError })(withWindowDimensions(InputTab) as any)