import React from 'react';
import { Page } from '../Page';
import Panel from '../common/Panel';
import { Box, TextField } from '@mui/material';
import Text, { FONT_STYLES_BY_TYPE } from '../common/styled/Text';
import { WithRouterProps, withRouter } from '../common/withRouter';
import { getCustomAssetDetailsById, updateAssetAllocationItem, updateAssetItem, updateAssetMetrics } from '../../actions/customAssets';
import { AssetItem, AssetMetrics, AssetAllocations, AllocationType } from '../../actions/types/customAssets';
import { sanitizeNumericInput } from '../../common/utils';
import { DatePicker } from '../common/styled/DatePicker';
import moment from 'moment';
import AllocationTable from './AllocationTable';
import { connect } from 'react-redux';
import { addError } from '../../actions/notifications';

interface AssetEditPageProps extends WithRouterProps {
    addError: (title: string, message: string) => void;
}

interface AssetEditPageState {
    item?: AssetItem;
    metrics?: AssetMetrics;
    sectors?: AssetAllocations[]
    credit_quality?: AssetAllocations[]
    maturity?: AssetAllocations[]
    equity_region?: AssetAllocations[]
    equity_sector?: AssetAllocations[]
    loading: boolean;
    launchDate?: Date;
}

const assetClasses = [
    'Mixed Assets', 
    'Commodity', 
    'Alternatives', 
    'Bond', 
    'Other', 
    'Real Estate', 
    'Money Market', 
    'Equity'
]

const AllocationToStateMapping: { [ key: string]: (keyof AssetEditPageState) } = {
    [AllocationType.FIXED_INCOME_SECTOR_BREAKDOWN]: 'sectors',
    [AllocationType.CREDIT_QUALITY_BREAKDOWN]: 'credit_quality',
    [AllocationType.MATURATIY_BREAKDOWN]: 'maturity',
    [AllocationType.EQUITY_REGION_BREAKDOWN]: 'equity_region',
    [AllocationType.EQUITY_SECTOR_BREAKDOWN]: 'equity_sector',
};

class AssetEditPage extends React.Component<AssetEditPageProps, AssetEditPageState> {
    state: AssetEditPageState = {
        loading: true,
    };

    componentDidMount(): void {
        const assetId = this.props.params.assetId
        if (!assetId || isNaN(Number(assetId))) {
            this.props.navigate('/account/assets');
        }

        this.loadAsset(Number(assetId))
    }

    loadAsset = async (assetId: number) => {
        this.setState({ loading: true })
        try {
                
            const { success, message, item, metrics, allocations } = await getCustomAssetDetailsById(assetId);
            if (!success) {
                this.props.addError('Asset Details', message ?? 'Failed to load asset details. Please try again later.')
                return;
            }
            const sectors = allocations?.filter(item => item.type === AllocationType.FIXED_INCOME_SECTOR_BREAKDOWN) ?? [];
            const credit_quality = allocations?.filter(item => item.type === AllocationType.CREDIT_QUALITY_BREAKDOWN) ?? [];
            const maturity = allocations?.filter(item => item.type === AllocationType.MATURATIY_BREAKDOWN) ?? [];
            const equity_region = allocations?.filter(item => item.type === AllocationType.EQUITY_REGION_BREAKDOWN) ?? [];
            const equity_sector = allocations?.filter(item => item.type === AllocationType.EQUITY_SECTOR_BREAKDOWN) ?? [];
            this.setState({
                item: {
                    ...(item ?? {}),
                    expense_ratio: !isNaN(Number(item?.expense_ratio)) ? Number(item?.expense_ratio) / 100 : item?.expense_ratio as any,
                } as AssetItem,
                metrics,
                sectors,
                credit_quality,
                maturity,
                equity_region,
                equity_sector,
            })
        } catch (e) {
            this.props.addError('Asset Details', 'Failed to load asset details. Please try again later.')
        }
        this.setState({ loading: false })
    }

    handleAssetChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const { item } = this.state;
        const isNumericItem = ['expense_ratio'].includes(name)
        const updateValue = isNumericItem ? sanitizeNumericInput(value) : value
        this.setState({
            item: {
                ...item,
                [name]: updateValue,
            }
        } as any);
    };

    handleAssetBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
        this.handleAssetChanges(event);
        const { name, value } = event.target;
        const { item } = this.state;
        const isExpenseRatio = name === 'expense_ratio' && !isNaN(Number(value))
        const { success, item: itemUpdate } = await updateAssetItem(item?.id ?? 0, { [name]: isExpenseRatio ? Number(value) * 100 : value })
        if (success && itemUpdate) {
            this.setState({ item: {
                ...itemUpdate,
                expense_ratio: !isNaN(Number(itemUpdate?.expense_ratio)) ? Number(itemUpdate?.expense_ratio) / 100 : itemUpdate?.expense_ratio as any,
            } })
        }
    };

    handleMetricChanges = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const { metrics } = this.state;
        const isNumericItem = [ 'distribution_yield', 'modified_duration'].includes(name)
        const updateValue = isNumericItem ? sanitizeNumericInput(value) : value
        this.setState({
            metrics: {
                ...metrics,
                [name]: updateValue,
            }
        } as any);
    };

    handleMetricBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
        const { name } = event.target;
        const { item, metrics } = this.state;
        const value = metrics ? metrics[name as keyof AssetMetrics] : undefined
        const { success, message, metrics: metricsUpdate } = await updateAssetMetrics(item?.id ?? 0, { [name]: value })
        if (!success || !metricsUpdate) {
            this.props.addError('Asset Details', message ?? 'Failed to save asset details. Please try again later.')

            return;
        }

        if (success && metricsUpdate) {
            this.setState({ metrics: metricsUpdate })
        }
    };

    onLaunchDateChanged = async (date: Date) => {
        const { item } = this.state;
        const { success, message, item: itemUpdate } = await updateAssetItem(item?.id ?? 0, { launch_date: moment(date).format('YYYY-MM-DD hh:mm:ss') })
        if (!success || !itemUpdate) {
            this.props.addError('Asset Details', message ?? 'Failed to save asset details. Please try again later.')

            return;
        }
        if (success && itemUpdate) {
            this.setState({ item: itemUpdate })
        }
    }

    onAllocationItemUpdated = async (item: AssetAllocations, value: number) => {
        const key: keyof AssetEditPageState = AllocationToStateMapping[item.type] as keyof AssetEditPageState
        const items: AssetAllocations[]  = (this.state[key] ?? []) as AssetAllocations[]
        const itemsUpdate = items.map(item => item.id === item.id ? item : item)
        this.setState({ [key]: itemsUpdate } as any)

        const { success, message, item: updateItem } = await updateAssetAllocationItem(item, value)
        if (!success || !updateItem) {
            this.props.addError('Asset Details', message ?? 'Failed to save asset details. Please try again later.')

            return
        }
        if (success && updateItem) {
            const allocations = items.map(item => item.id === updateItem.id ? updateItem : item)
            this.setState({ [key]: allocations } as any)
        }
    }

    render() {
        const { loading, item, metrics } = this.state;
        if (loading) {
            return (
            <Page>
                <Panel
                    title={'Asset Edit'}
                    sx={{
                        width: '100%',
                        margin: {
                            xs: undefined,
                            md: '2rem',
                        },
                        marginBottom: '2rem',
                    }}
                    contentsSx={{
                        padding: '2rem',
                    }}>
                </Panel>
            </Page>
            )
        }
        const { symbol_display, name, description, launch_date, asset_class, expense_ratio, } = item || {};
        const { distribution_yield, modified_duration } = metrics || {};
        
        return (
            <Page sx={{
                overflow: 'unset',
                overflowY: 'unset',
                mb: '140rem'
            }}>
                <Panel
                    title={`Asset Edit - ${symbol_display}`}
                    sx={{
                        width: '100%',
                        margin: {
                            xs: undefined,
                            md: '2rem',
                        },
                        marginBottom: '2rem',
                    }}
                    contentsSx={{
                        padding: '2rem',
                    }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }} gap={'2.5rem'}>
                                <TextField
                                    name="name"
                                    label="Name"
                                    variant="outlined"
                                    fullWidth
                                    value={name}
                                    onChange={this.handleAssetChanges}
                                    onBlur={this.handleAssetBlur}
                                    margin="normal"
                                    inputProps={{
                                        sx: {
                                            font: FONT_STYLES_BY_TYPE.text,
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            font: FONT_STYLES_BY_TYPE.text,
                                        }
                                    }}
                                />
                                <TextField
                                    name="description"
                                    label="Description"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    value={description}
                                    onChange={this.handleAssetChanges}
                                    onBlur={this.handleAssetBlur}
                                    margin="normal"
                                    inputProps={{
                                        sx: {
                                            font: FONT_STYLES_BY_TYPE.text,
                                        }
                                    }}
                                    InputLabelProps={{
                                        sx: {
                                            font: FONT_STYLES_BY_TYPE.text,
                                        }
                                    }}
                                />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }} gap={'2.5rem'}>
                                    <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    }}>
                                    <Text>Launch Date</Text>
                                    <DatePicker
                                        date={launch_date ? moment(launch_date).toDate() : ''}
                                        format={'MM/dd/yyyy'}
                                        placeholderText={'__/__/____'}
                                        onDateChanged={this.onLaunchDateChanged} 
                                    />
                                </Box>
                            <TextField
                                name="asset_class"
                                label="Asset Class"
                                variant="outlined"
                                fullWidth
                                value={asset_class}
                                onChange={this.handleAssetBlur}
                                margin="normal"
                                select={true}
                                SelectProps={{
                                    native: true,
                                }}
                                inputProps={{
                                    sx: {
                                        font: FONT_STYLES_BY_TYPE.text,
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        font: FONT_STYLES_BY_TYPE.text,
                                    }
                                }}
                            >
                                {assetClasses.map((item, index) => (
                                    <option key={item} value={item}>{item}</option>
                                ))}
                            </TextField>
                            <TextField
                                name="expense_ratio"
                                label="Expense Ratio (%)"
                                type="number"
                                variant="outlined"
                                fullWidth
                                value={expense_ratio}
                                onChange={this.handleAssetChanges}
                                onBlur={this.handleAssetBlur}
                                margin="normal"
                                inputProps={{
                                    sx: {
                                        font: FONT_STYLES_BY_TYPE.text,
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        font: FONT_STYLES_BY_TYPE.text,
                                    }
                                }}
                            />
                            <TextField
                                name="distribution_yield"
                                label="Distribution Rate (%)"
                                type="number"
                                variant="outlined"
                                fullWidth
                                value={distribution_yield}
                                onChange={this.handleMetricChanges}
                                onBlur={this.handleMetricBlur}
                                margin="normal"
                                inputProps={{
                                    sx: {
                                        font: FONT_STYLES_BY_TYPE.text,
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        font: FONT_STYLES_BY_TYPE.text,
                                    }
                                }}
                            />
                            <TextField
                                name="modified_duration"
                                label="Duration (Years)"
                                type="number"
                                variant="outlined"
                                fullWidth
                                value={modified_duration}
                                onChange={this.handleMetricChanges}
                                onBlur={this.handleMetricBlur}
                                margin="normal"
                                inputProps={{
                                    sx: {
                                        font: FONT_STYLES_BY_TYPE.text,
                                    }
                                }}
                                InputLabelProps={{
                                    sx: {
                                        font: FONT_STYLES_BY_TYPE.text,
                                    }
                                }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
                            <AllocationTable type={AllocationType.CREDIT_QUALITY_BREAKDOWN}  items={this.state.credit_quality ?? []} onValueUpdated={this.onAllocationItemUpdated} />
                            <AllocationTable type={AllocationType.FIXED_INCOME_SECTOR_BREAKDOWN}  items={this.state.sectors ?? []} onValueUpdated={this.onAllocationItemUpdated} />
                            <AllocationTable type={AllocationType.MATURATIY_BREAKDOWN}  items={this.state.maturity ?? []} onValueUpdated={this.onAllocationItemUpdated} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
                            <AllocationTable type={AllocationType.EQUITY_REGION_BREAKDOWN}  items={this.state.equity_region ?? []} onValueUpdated={this.onAllocationItemUpdated} />
                            <AllocationTable type={AllocationType.EQUITY_SECTOR_BREAKDOWN}  items={this.state.equity_sector ?? []} onValueUpdated={this.onAllocationItemUpdated} />
                        </Box>
                </Panel>
            </Page>
        );
    }
}

export default connect(null, { addError })(withRouter(AssetEditPage));
